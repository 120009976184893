<template>
  <div>
    <div class="row">
     <div class="col-md-12">
          <h2 class="h2 text-default ">
           {{$t('make-review')}}
          </h2>
          <textarea class="form-control" rows="3" v-model="post_data.review"></textarea>
          <span class="text-warning">{{review_error}}</span>
          <div class="star-rating" v-if="can_give_entity_rating">
            <em class="fa fa-star"  @click="setStars(1)" id="star-1" @mouseover="ratehandle(1)"></em>
            <em  class="fa fa-star" @click="setStars(2)" id="star-2" @mouseover="ratehandle(2)"></em>
            <em  class="fa fa-star" @click="setStars(3)" id="star-3" @mouseover="ratehandle(3)"></em>
            <em  class="fa fa-star" @click="setStars(4)" id="star-4" @mouseover="ratehandle(4)"></em>
            <em  class="fa fa-star" @click="setStars(5)" id="star-5" @mouseover="ratehandle(5)"></em>
              <em class="ml-3 badge badge-dark badge-circle" >{{post_data.star_rating}}</em>
          </div><br/>
          <button class="btn btn-primary mt-2" @click="submitReview">{{$t('submit')}}</button>
     </div>
    </div>
    <div class="row">
      <div class="display-5 col-10" style="height: 20px">{{$t('reviews')}}</div>
    </div>
    <div class="row mt-3 " style="height: 500px;overflow: auto">
      <div class="col-md-12" v-for="review in show_reviews" :key="review.uuid">
        <div class="card p-3">
          <div class>
            <span>{{ review.name }}</span>
            <span class="text-muted ml-3 float-lg-right">{{
              $date_format.date_only(review.doneAt)
            }}</span>
            <div class="ml-auto" v-if="review.star_rating>0">
              <star-rating :rating="review.star_rating" />
            </div>
          </div>
          <div class="coment">
            <p class="text-muted">{{ review.description }}</p>
          </div>
          <div class="ml-4 mt--2" v-if="review.owner_comment!=null">
             <span class=""><strong>Seller Answer</strong></span>
                <textarea class="form-control" disabled v-model="review.owner_comment">
             </textarea>
          </div>
           <div class="ml-4" v-if="owner && review.owner_comment==null">
             <span class=""><strong>Provide reply</strong></span>
             <div>
               <textarea class="form-control" v-model="review_reply"></textarea>
               <button class="btn btn-sm btn-outline-primary" @click="reply(review)">Reply</button>
             </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import StarRating from "../components/StarRating";
import ratingMixin from "@/mixins/rating_mixins";
import Helpers from "@/utils/helpers";


export default {
  name: "EntityReview",
  components: {
    "star-rating": StarRating,
  },
  mixins: [ratingMixin],
  props: {
    entityUuid: {
      required: true,
    },
    entityRate: {
      required: false,
    },
    entity:{
      required:false
    },
    owner:{
      required:false,
      default:false
    }
    
  },
  data() {
    return {
      reviews: [],
      show_reviews: [],
      entityRating: this.entityRate,
      star_count: [],
      post_data:{
        review:"",
        entity_uuid:this.entityUuid,
        star_rating:3
      },
      review_reply:"",
      review_error:""
    };
  },
  watch: {
    entityUuid: function () {
      this.fetch_reviews();
    },
  },
  methods: {
    reply:function(evt){
      if(this.review_reply.length>0){
           axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL}/api/products/review/seller/reply/`,
          {
            "review_uuid":evt.uuid,
            "reply":this.review_reply
          },
        )
        .then((res) => {
          this.fetch_reviews()
           console.log(res)
        })
      }
    },

    submitReview:function(){
      this.post_data.entity_uuid=this.entityUuid
      if(this.post_data.review.length>0){
        this.review_error=""
           axios
        .post(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/custom/makereview/`,
          this.post_data,
        )
        .then(() => {
          this.fetch_reviews()
          Helpers.saveRating(this.post_data.entity_uuid,"entity")
        })
      }else{
        this.review_error= this.$i18n.locale=='kin'?"Nta gitecyerezo cyatanzwe":"No Comment provided"
      }
    },
    fetch_reviews: function () {
      axios
        .get(`${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.entity.uuid}/review`)
        .then((res) => {
          if (res.status === 200) {
            this.reviews = res.data
            this.count_stars();
            this.get_showing_reviews();
          }
        })

    },
    count_stars: function () {
      this.star_count = [];
      for (var num = 5; num > 0; num--) {
        let data = {
          num: num,
          count: 0,
          percentage: 0,
        };
        this.reviews.forEach((rev) => {
          if (parseInt(rev.star_rating) == num) {
            data.count += 1;
          }
        });
        data.percentage = (data.count * 100) / this.reviews.length;
        this.star_count.push(data);
      }
    },
    get_showing_reviews: function () {
      this.reviews.sort(function () {
        return 0.5 - Math.random();
      });
      this.show_reviews = this.reviews
    },
  },
  created() {
    this.fetch_reviews();
  },
};
</script>
<style lang="css" scoped>
.progress-bar {
  background-color: gold !important;
}

 .star-rating{
        display: inline-block;
        font-size: 1vmax;
        /* color: yellowgreen; */
        position: relative;
    }
    .star-rating .star-rating__current{
        position: absolute;
        top: 0;
        overflow: hidden;
        white-space: nowrap;

    }
</style>