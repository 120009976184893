<template>
  <div class="container-fluid" id="panel">
     <div class="row mt-8 d-flex justify-content-center">
       <div class="col-md-11 mt-2">
          <div class="row" style="border-bottom-color: silver" >
            <div class="col-md-12 mt-5">
                <router-link
                  v-if="entity.uuid"
                  :to="{
                    name: 'Company Profile',
                    params: {
                      slug: entity?.slug,
                    },
                  }"
                >
                  <div class="card card-profile">
                      <div class="row justify-content-center">
                        <div class="col-lg-3 order-lg-2">
                          <div class="card-profile-image">
                            <a href="#">
                              <img class="card-img rounded-circle" :src="entity.logo" alt="Card image" style="height: 150px;">
                            </a>
                          </div>
                        </div>
                      </div>
                      <div
                        class="card-header text-center border-0 pt-5 pt-md-4 pb-0 pb-md-4"
                      ></div>
                      <div class="card-body pt-0 text-muted">
                        <h2 class="h3 mt-5 text-center">{{ entity.name }}</h2>

                        <div class="text-center">
                            <strong>{{entity.phone}}</strong>
                        </div>
                        <div class="text-center">
                            <strong>{{entity.address}}</strong>
                        </div>
                        
                        <div class="text-center">
                            <strong v-html="entity.description"></strong>
                        </div>
                        <div class="text-center">
                              <small class="text-muted">
                                           <star-rating
                                            v-if="entity.ratings <= 5 && entity.ratings > 0"
                                            :rating="entity.ratings"
                                          ></star-rating>
                                      </small>

                        </div>
                       </div>

                  </div>
                </router-link>
            </div>

          </div><!-- Entity Details-->
          <div class="row" v-if="products.length>0">
            <div class="col-md-9">
               <div class="row">
                 <div class="col-md-3" v-for="product in products" :key="product.uuid" style="cursor:pointer" @click="viewProductDetails(product)" >
                   <ProductCard
                       :product="product"
                   ></ProductCard>
                 </div>
               </div>
            </div>
            <div class="col-md-3">
              <entity-reviews :entity="entity" :entityRate="entity.ratings" :entityUuid="entity.uuid" :owner="is_owner"></entity-reviews>
            </div>
          </div>

       </div>
     </div> 
  </div>
</template>
<script>

import EntityReview from "../components/EntityReview.vue"
import ProductCard from "@/components/ProductCard";
import axios from "axios";
import StarRating from "../components/StarRating";
export default {
  components: {
    ProductCard,
    "star-rating": StarRating,
    "entity-reviews": EntityReview,
  },
  data() {
    return {
      slideTitle: "Explore Popular Categories",
      products: [],
      services: [],
      entity: {},
      contacts: [],
      backend_url: process.env.VUE_APP_BACKEND_URL,
      root_img_url:process.env.VUE_APP_IMG_URL,
      socials: [],
      is_owner:false
    };
  },
  methods: {
    fetch_products: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.entity.uuid}/products/`
        )
        .then((res) => {
          this.products = res.data
        })
        .catch((err) => {
          console.error(err);
        });
    },
   
   
    fetch_socials: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/${this.entity.uuid}/socials`
        )
        .then((res) => {
          this.socials = res.data;
        })
        .catch(() => {
        });
    },
    viewProductDetails:function(pro){
      this.$router.push({name:"Product Details",params:{slug:pro.slug}})
    },
    get_details: function () {
      axios
        .get(
          `${process.env.VUE_APP_BACKEND_URL}/api/entities/public/${this.$route.params.slug}`
        )
        .then((res) => {
           if(this.$store.state.auth.Profile){
              if(this.$store.state.auth.userProfile.entity.uuid===res.data.uuid){
                 this.is_owner=true
              }else{
                this.is_owner=false
              }
           }
          this.entity = res.data;
           document.title= "BCM-"+this.entity.name
          this.entity.women_percentage = (
            (this.entity.no_of_women_employees * 100) /
            this.entity.no_of_employees
          ).toFixed(2);
          this.$store.commit('update_current_entity_uuid',res.data.uuid)

            this.fetch_products();
            this.fetch_socials();
        })
        .catch(() => {
        });
    },
  },
  created() {
    this.get_details();
  },
};
</script>

<style>
</style>